import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store/store'

type urlState = {
  url: string
}

const initialState: urlState = {
  url: '',
}

const urlSlice = createSlice({
  name: 'url',
  initialState: initialState,
  reducers: {
    urlReducer(state, action) {
      state.url = action.payload
    },
  },
})

export const { urlReducer } = urlSlice.actions

export const selectUrlState = (state: RootState) => state.url.url

export default urlSlice.reducer
