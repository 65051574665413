'use client'
import { Header } from '../components/layouts/Header'
import { Footer } from '../components/layouts/Footer'
import { usePathname } from 'next/navigation'
import { Provider } from 'react-redux'
import { store } from '@/store/store'

export default function GroupLayout({
  children,
}: {
  children: React.ReactNode
}) {
  const pathname = usePathname() ?? ''

  return (
    <>
      <Provider store={store}>
        <Header path={pathname} />
        <div className="l-wrapper">
          <main>{children}</main>
        </div>
        <Footer />
      </Provider>
    </>
  )
}
